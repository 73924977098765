import {HeaderType} from "@/Api/Friend/interfaceType";
import Axios from "@/Api/Config/friend";
import {
    AddCommentType,
    BodyType, CollCommentType,
    GroupSquareType,
    GroupSucType,
    JoinGroupType, joinListType,
    MyJoinType, PayBeforeType, SecondCommentType,
    SetIssueGroupType, UpdateGroupStateType,
    UpdateGroupType
} from "@/Api/Group/indexType";

// 发布团购
export function SetIssueGroup( headers:HeaderType,body:SetIssueGroupType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/addTeamTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 团购广场
export function GroupSquare( headers:HeaderType,body:GroupSquareType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/taskList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 热门团购
export function HotGroup( headers:HeaderType,body:BodyType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/popularTeamTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我参加的团购
export function MyJoin( headers:HeaderType,body:MyJoinType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/queryIJoinTeamTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看自己发布的组团
export function MyGroup( headers:HeaderType,body:MyJoinType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/queryOnselfTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看组团的评论
export function GroupComment( headers:HeaderType,body:GroupSquareType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/moment/teamTaskComment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看组团详情
export function GroupSucData( headers:HeaderType,body:GroupSucType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/queryTaskById",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除我的组团
export function DelGroup( headers:HeaderType,body:GroupSucType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/deleteTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改组团
export function UpdateGroup( headers:HeaderType,body:UpdateGroupType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/updateTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 加入组团
export function JoinGroup( headers:HeaderType,body:JoinGroupType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/addTask",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 支付前
export function PayBefore(headers:HeaderType,body:PayBeforeType) {
    return (
        Axios({
            url:"/app/weixin/weixinApiPay",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 支付后
export function PayAfter(headers:HeaderType,body:PayBeforeType) {
    return (
        Axios({
            url:"/app/weixin/callBack",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改组团状态
export function UpdateGroupState( headers:HeaderType,body:UpdateGroupStateType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/updateTaskStatus",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加评论
export function AddComment( headers:HeaderType,body:AddCommentType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/moment/addTaskComment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除评论
export function DelComment( headers:HeaderType,body:GroupSucType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/moment/deleteComment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 点赞收藏评论
export function CollComment( headers:HeaderType,body:CollCommentType ):Promise<any> {
    return (
        Axios({
            url:"/app/task/like/addLike",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查看二级评论
export function getSecondComment(headers:HeaderType,body:SecondCommentType) {
    return (
        Axios({
            url:"/app/task/moment/secondLevelComment",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 参加列表
export function joinList(headers:HeaderType,body:joinListType) {
    return (
        Axios({
            url:"/app/task/joinTaskList",
            method:"POST",
            headers,
            data:body
        })
    )
}
