

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import IssueGroupStoreType from "@/views/Group/IssueGroup/Store/indexType";
import {IssueFriendAddressType} from "@/views/Friend/IssueFriend/Store/indexType";

@Module({
    name:"IssueGroup",
    store,
    dynamic: true,
})
export default class IssueGroup extends VuexModule implements IssueGroupStoreType{

    IssueGroupAddress: IssueFriendAddressType = {
        state:"off",
        address:"不显示位置"
    };
    get getIssueGroupAddress(){ return this.IssueGroupAddress }
    @Mutation
    SetIssueGroupAddress(data: IssueFriendAddressType): void {
        this.IssueGroupAddress = data
    }

    IssueGroupEndTime = "";
    get getIssueGroupEndTime(){ return this.IssueGroupEndTime }
    @Mutation
    SetIssueGroupEndTime(data: string): void {
        this.IssueGroupEndTime = data
    }

    IssueGroupImgList: string[] = [];
    get getIssueGroupImgList(){ return this.IssueGroupImgList }
    @Mutation
    SetIssueGroupImgList(data: string[]): void {
        this.IssueGroupImgList = data
    }

    IssueGroupPeople: number | string = "";
    get getIssueGroupPeople(){ return this.IssueGroupPeople }
    @Mutation
    SetIssueGroupPeople(data: number | string): void {
        this.IssueGroupPeople = data
    }

    IssueGroupPrice: number | string = "";
    get getIssueGroupPrice(){ return this.IssueGroupPrice }
    @Mutation
    SetIssueGroupPrice(data: number | string): void {
        this.IssueGroupPrice = data
    }

    IssueGroupStartTime = "";
    get getIssueGroupStartTime(){ return this.IssueGroupStartTime }
    @Mutation
    SetIssueGroupStartTime(data: string): void {
        this.IssueGroupStartTime = data
    }

    IssueGroupTitle = "";
    get getIssueGroupTitle(){ return this.IssueGroupTitle }
    @Mutation
    SetIssueGroupTitle(data: string): void {
        this.IssueGroupTitle = data
    }

    IssueGroupContent = "";
    get getIssueGroupContent(){ return this.IssueGroupContent }
    @Mutation
    SetIssueGroupContent(data: string): void {
        this.IssueGroupContent = data
    }

    IssueGroupMessage = "";
    get getIssueGroupMessage(){ return this.IssueGroupMessage }
    @Mutation
    SetIssueGroupMessage(data: string): void {
        this.IssueGroupMessage = data
    }

    IssueGroupClear = false
    get getIssueGroupClear(){ return this.IssueGroupClear }
    @Mutation
    SetIssueGroupClear(data:boolean){
        this.IssueGroupAddress = {
            state:"off",
            address:"不显示位置"
        }
        this.IssueGroupEndTime = ""
        this.IssueGroupStartTime = ""
        this.IssueGroupImgList = []
        this.IssueGroupPeople = ""
        this.IssueGroupPrice = ""
        this.IssueGroupTitle = ""
        this.IssueGroupContent = ""
        this.IssueGroupMessage = ""
        this.IssueGroupClear = data
    }

    IssueGroupUpDateState = false;
    get getIssueGroupUpDateState(){ return this.IssueGroupUpDateState }
    @Mutation
    SetIssueGroupUpDateState(data: boolean): void {
        if ( data ){
            this.IssueGroupUpDateState = true
            let time = setTimeout(()=>{
                this.IssueGroupUpDateState = false
                clearTimeout(time)
            },300)
        }else{
            this.IssueGroupUpDateState = data
        }
    }
}

export const IssueGroupStore = getModule( IssueGroup )
