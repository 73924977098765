import Storage from "@/util/Storage";
import {UpPhotos} from "@/Api/UpPhoto";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";
import {Toast} from "vant";
import {SetIssueGroupType, UpdateGroupType} from "@/Api/Group/indexType";
import {SetIssueGroup, UpdateGroup} from "@/Api/Group";
// eslint-disable-next-line
// @ts-ignore
import moment from "moment";


export function upPhotos( list:any[] = [] ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    const fromData = new FormData()
    fromData.append("functionName","other")
    list.forEach(item=>fromData.append("files",item.file, item.file.name))
    return Promise.resolve( UpPhotos({ userId,token },fromData).then(res=>{
        return res.data
    }) )
}

export function getImgListStr(list:string[]) {
    if ( list.length < 1 )return "";
    let strPath = ""
    list.forEach((item,index)=>{
        if ( index ){
            strPath += (","+item)
        }else{
            strPath = item
        }
    })
    return strPath
}

// 发布
export function IssueGrouping() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    const userInfo = JSON.parse( Storage.GetData_ && Storage.GetData_("UserData") || "{}" )
    let title = IssueGroupStore.getIssueGroupTitle,
        content = IssueGroupStore.getIssueGroupContent,
        imgList = IssueGroupStore.getIssueGroupImgList,
        address = IssueGroupStore.getIssueGroupAddress,
        money = IssueGroupStore.getIssueGroupPrice + '',
        people = IssueGroupStore.getIssueGroupPeople + '',
        startTime = IssueGroupStore.getIssueGroupStartTime,
        endTime = IssueGroupStore.getIssueGroupEndTime,
        message = IssueGroupStore.getIssueGroupMessage,
        upData:SetIssueGroupType = {
            endDate: "",
            img: "",
            personNumber: 0,
            phone:userInfo.phone || "",
            price: 0,
            startDate: "",
            status: 0,
            taskAddress: "",
            taskContent: "",
            taskName: "",
            remark: "",
            taskTime: ""
        };

    if ( title.length ){
        upData.taskName = title
    }else{
        Toast("请输入标题")
        return Promise.reject();
    }
    if (content.length){
        upData.taskContent = content
    }else{
        Toast("请输入任务内容")
        return Promise.reject();
    }
    if (money.length){
        upData.price = money
    }else{
        Toast("请输入金额")
        return Promise.reject();
    }
    if (people.length){
        upData.personNumber = people
    }else{
        Toast("请输入任务人数")
        return Promise.reject();
    }
    if (startTime.length){
        upData.startDate = startTime
    }else{
        Toast("请选择开始时间")
        return Promise.reject();
    }
    if (endTime.length){
        upData.endDate = endTime
    }else{
        Toast("请选择结束时间")
        return Promise.reject();
    }
    if (message.length){
        upData.remark = message
    }

    let minTime = moment(startTime),
        maxTime = moment(endTime);
    let max = moment.max(minTime,maxTime),
        min = moment.min(minTime,maxTime);
    upData.taskTime = Math.floor( Math.abs( max.diff(min,"hour") ) ) + '小时'

    if (imgList.length){
        upData.img = getImgListStr(imgList)
    }
    if ( address.state == "ok" ){
        if ( address.showSiteStr ){
            upData.taskAddress = address.showSiteStr
        }else if ( address.address ){
            upData.taskAddress = address.address
        }else {
            upData.taskAddress = `${ address.city }${ address.district }${ address.township }`
        }
    }else{
        upData.taskAddress = ""
    }

    Toast.loading({ message:"发布中~~~",duration:0 })
    return Promise.resolve( SetIssueGroup({ userId,token },upData).then(res=>{
        Toast.clear()
        Toast("发布成功")
        IssueGroupStore.SetIssueGroupClear(true)
        let time = setTimeout(()=>{
            IssueGroupStore.SetIssueGroupClear(false)
            clearTimeout( time )
        },1000)
        return res.data
    }) )
}

// 修改
export function updateGrouping(data:any) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    const userInfo = JSON.parse( Storage.GetData_ && Storage.GetData_("UserData") || "{}" )
    let title = IssueGroupStore.getIssueGroupTitle,
        content = IssueGroupStore.getIssueGroupContent,
        imgList = IssueGroupStore.getIssueGroupImgList,
        address = IssueGroupStore.getIssueGroupAddress,
        money = IssueGroupStore.getIssueGroupPrice + '',
        people = IssueGroupStore.getIssueGroupPeople + '',
        startTime = IssueGroupStore.getIssueGroupStartTime,
        endTime = IssueGroupStore.getIssueGroupEndTime,
        message = IssueGroupStore.getIssueGroupMessage,
        upData:UpdateGroupType|any = {
            id:data.id,
            endDate: "",
            img: "",
            personNumber: 0,
            phone:userInfo.phone || "",
            price: 0,
            startDate: "",
            status: 0,
            taskAddress: "",
            taskContent: "",
            taskName: "",
            remark: "",
            taskTime: ""
        },
        num = 0;
    if ( title.length ){
        if ( title != data.taskName )num ++;
        upData.taskName = title
    }else{
        Toast("请输入标题")
        return Promise.reject();
    }
    if ( content.length ){
        if ( content != data.taskContent )num ++;
        upData.taskContent = content
    }else{
        Toast("请输入任务内容")
        return Promise.reject();
    }
    if (money.length){
        if ( money != data.price )num ++;
        upData.price = money
    }else{
        Toast("请输入金额")
        return Promise.reject();
    }
    if (people.length){
        if ( people != data.personNumber )num ++;
        upData.personNumber = people
    }else{
        Toast("请输入任务人数")
        return Promise.reject();
    }
    if (startTime.length){
        if ( startTime != data.startDate )num ++;
        upData.startDate = startTime
    }else{
        Toast("请选择开始时间")
        return Promise.reject();
    }
    if (endTime.length){
        if ( endTime != data.endDate )num ++;
        upData.endDate = endTime
    }else{
        Toast("请选择结束时间")
        return Promise.reject();
    }
    if (message.length){
        if ( message != data.remark )num ++;
        upData.remark = message
    }

    let minTime = moment(startTime),
        maxTime = moment(endTime);
    let max = moment.max(minTime,maxTime),
        min = moment.min(minTime,maxTime);
    let timeStr = Math.floor( Math.abs( max.diff(min,"hour") ) ) + '小时'
    if ( timeStr != data.taskTime ) num ++;
    upData.taskTime = timeStr

    if (imgList.length){
        let strImg = getImgListStr(imgList)
        if ( strImg != data.img ) num ++;
        upData.img = strImg
    }else{
        upData.img = ''
    }
    if ( address.state == "ok" ){
        if ( address.showSiteStr ){
            upData.taskAddress = address.showSiteStr
        }else if ( address.address ){
            upData.taskAddress = address.address
        }else {
            upData.taskAddress = `${ address.city }${ address.district }${ address.township }`
        }
    }else{
        upData.taskAddress = ""
    }
    if ( upData.taskAddress != data.taskAddress ) num ++;
    if ( num < 1 ){
        Toast("请修改内容")
        return Promise.reject();
    }

    Toast.loading({ message:"修改中~~~",duration:0 })
    return Promise.resolve( UpdateGroup({ userId,token },upData).then(res=>{
        Toast.clear()
        Toast("修改成功")
        IssueGroupStore.SetIssueGroupClear(true)
        let time = setTimeout(()=>{
            IssueGroupStore.SetIssueGroupClear(false)
            clearTimeout( time )
        },1000)
        return res.data
    }) )
}
