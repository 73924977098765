import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

// 更新界面
export function updateFun(data:any) {
    if ( data.taskName && data.taskName.length )IssueGroupStore.SetIssueGroupTitle(data.taskName);
    if ( data.taskContent && data.taskContent.length )IssueGroupStore.SetIssueGroupContent(data.taskContent);
    if ( data.img && data.img.length )IssueGroupStore.SetIssueGroupImgList(data.img.split(","));
    if ( data.taskAddress && data.taskAddress.length ){
        let addressData = IssueGroupStore.getIssueGroupAddress;
        addressData.showSiteStr = data.taskAddress
        addressData.state = "ok"
        IssueGroupStore.SetIssueGroupAddress(addressData);
    }
    if ( data.price )IssueGroupStore.SetIssueGroupPrice(data.price);
    if ( data.personNumber )IssueGroupStore.SetIssueGroupPeople(data.personNumber || 0);
    if ( data.startDate && data.startDate.length )IssueGroupStore.SetIssueGroupStartTime(data.startDate);
    if ( data.endDate && data.endDate.length )IssueGroupStore.SetIssueGroupEndTime(data.endDate);
    if ( data.remark && data.remark.length )IssueGroupStore.SetIssueGroupMessage(data.remark);

    console.log( data )
    IssueGroupStore.SetIssueGroupUpDateState(true)
}
